import ApiService from "@/common/api.service";
import { AxiosRequestConfig } from 'axios';
import { AuthenticationError } from '@/common/AuthenticationError';
import { ResponseError } from "@/common/ResponseError";

const ListingService = {
    getListings: async(queryParams: any) => {
        const { pageNumber = 1, recordsPerPage = 3, orderBy = 'DESC', searchKey = ''} = queryParams;
        try {
            return ApiService.get(`/listing/list?pageNumber=${pageNumber}&recordsPerPage=${recordsPerPage}&orderBy=${orderBy}&searchKey=${searchKey}`);
        } catch(errors: any) {
            throw new ResponseError(
                errors.status,
                errors.error.message
            )
        }
    },

    getListing: async(listId: number) => {
        try {
            return ApiService.get(`/listing/view?listId=${listId}`);
        } catch(errors: any) {
            throw new ResponseError(
                errors.status,
                errors.error.message
            )
        }
    },

    post: async function(formData: any) {
        const configOptions: AxiosRequestConfig = {
            method: "post",
            headers: { "Content-Type": "application/json" },
            url: "/listing/post",
            data: formData
        };

        try {
            return await ApiService.customRequest(configOptions);
        } catch (error: any) {
            this.catchError(error);
        }
    },

    getManage: async(queryParams: any) => {
        const { pageNumber = 1, recordsPerPage = 3, orderBy = 'DESC', searchKey = ''} = queryParams;
        try {
            return ApiService.get(`/biz/manage?pageNumber=${pageNumber}&recordsPerPage=${recordsPerPage}&orderBy=${orderBy}&searchKey=${searchKey}`);
        } catch(errors: any) {
            throw new ResponseError(
                errors.status,
                errors.error.message
            )
        }
    },

    getManageView: async(listId: number) => {
        try {
            return ApiService.get(`/biz/manageview?listId=${listId}`);
        } catch(errors: any) {
            throw new ResponseError(
                errors.status,
                errors.error.message
            )
        }
    },

    update: async function(formData: any) {
        const configOptions: AxiosRequestConfig = {
            method: 'post',
            headers: { 'Content-Type': 'application/json'},
            url: `/biz/update?listId=${formData.listId}`,
            data: formData
        };

        try {
            return await ApiService.customRequest(configOptions);
        } catch (error: any) {
            this.catchError(error);
        }
    },

    uploadImage: async function(formData: any) {
        const configOptions: AxiosRequestConfig = {
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            url: `/biz/uploadimage?listId=${formData.get('listId')}`,
            data: formData
        };

        try {
            return await ApiService.customRequest(configOptions);
        } catch (error: any) {
            this.catchError(error);
        }
    },

    updateFundingDetails: async function(formData: any) {
        const configOptions: AxiosRequestConfig = {
            method: 'post',
            headers: { 'Content-Type': 'application/json'},
            url: `/biz/updatefundingdetails?listId=${formData.listId}`,
            data: formData
        };

        try {
            return await ApiService.customRequest(configOptions);
        } catch (error: any) {
            this.catchError(error);
        }
    },

    updateConnection: async function(formData: any) {
        const configOptions: AxiosRequestConfig = {
            method: 'post',
            headers: { 'Content-Type': 'application/json'},
            url: `/biz/updateconnection?listId=${formData.listId}`,
            data: formData
        };

        try {
            return await ApiService.customRequest(configOptions);
        } catch (error: any) {
            this.catchError(error);
        }
    },

    updateEnhance: async function(formData: any) {
        const configOptions: AxiosRequestConfig = {
            method: 'post',
            headers: { 'Content-Type': 'application/json'},
            url: `/biz/updateenhance?listId=${formData.get('listId')}`,
            data: formData
        };

        try {
            return await ApiService.customRequest(configOptions);
        } catch (error: any) {
            this.catchError(error);
        }
    },

    updatePayout: async function(formData: any) {
        console.log(formData, formData.listId);
        const configOptions: AxiosRequestConfig = {
            method: 'post',
            headers: { 'Content-Type': 'application/json'},
            url: `/biz/updatepayout?listId=${formData.listId}`,
            data: formData
        };

        try {
            return await ApiService.customRequest(configOptions);
        } catch (error: any) {
            this.catchError(error);
        }
    },

    catchError: function(error: any) {
        let status;
        let description;

        if (error.response === undefined) {
            status = error.message;
            description = error.message;
        } else {
            status = error.response.status;
            description = error.response.data;
        }

        throw new AuthenticationError(status, description);
    }
}

export { ListingService };