import { ConfigUtil } from '@/common/config.util';

const TokenService = {

    getAuthData() {
        const authData = localStorage.getItem(ConfigUtil.authDataKey);
        return (authData) ? JSON.parse(authData) : '';
    },

    saveAuthData(authData: any) {
        const expirationTime = new Date().getTime() + +authData.authTokenExpirationTime;
        const data = JSON.stringify({
            authToken: authData.accessToken,
            authTokenExpirationTime: expirationTime,
            permissions: authData.permissions,
            username: authData.username,
            profileUpdate: authData.profileUpdate,
            isAuthenticated: true,
            refreshToken: authData.refreshToken
        });
        localStorage.setItem(ConfigUtil.authDataKey, data);
    },

    removeAuthData() {
        localStorage.removeItem(ConfigUtil.authDataKey);
    },

    getParsedAuthData() {
        const response = localStorage.getItem(ConfigUtil.authDataKey);
        
        const parsedData = (response) ? JSON.parse(response) : '';
           const data = {
            authToken: parsedData.authToken,
            authTokenExpirationTime: parsedData.authTokenExpirationTime,
            isAuthenticated: (parsedData.isAuthenticated) ? true : false,
            refreshToken: parsedData.refreshToken,
            username: parsedData.username,
            permissions: parsedData.permissions,
            profileUpdate: parsedData.profileUpdate
        };
        return data;
    },

    getToken() {
        const { authToken } = this.getParsedAuthData();
        return authToken;
    },

    getAuthStatus() {
        const { isAuthenticated } = this.getParsedAuthData();
        return isAuthenticated;
    },

    getRefreshToken() {
        const { refreshToken } = this.getParsedAuthData();
        return refreshToken;
    },
};

export { TokenService };