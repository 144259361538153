import axios, { AxiosRequestConfig } from 'axios';
import { store } from '@/store';
import { TokenService} from '@/common/token.service';
import { loadingController } from '@ionic/vue';

const ApiService = {
    requestInterceptor: 0,
    code401interceptor: 0,

    init(baseURL: string | undefined) {
        axios.defaults.baseURL = baseURL;
    },

    setHeader() {
        axios.defaults.headers.common[
            'Authorization'
            ] = `Bearer ${TokenService.getToken()}`;
    },

    removeHeader() {
        axios.defaults.headers.common = {};
    },

    get(resource: string) {
        return axios.get(resource);
    },

    post(resource: string, data: any) {
        return axios.post(resource, data);
    },

    put(resource: string, data: any) {
        return axios.put(resource, data);
    },

    delete(resource: string) {
        return axios.delete(resource);
    },

    customRequest(data: AxiosRequestConfig) {
        return axios(data);
    },

    mountRequestInterceptor() {
        this.requestInterceptor = axios.interceptors.request.use(async config => {
            const loading = await loadingController.create({
                message: 'Please wait...',
                duration: 5000,
            });
            await loading.present();
            return config;
        });
    },

    mount401Interceptor() {
        this.code401interceptor = axios.interceptors.response.use(
            response => {
                loadingController.dismiss().then(r => console.log(r));
                return response;
            },
            async error => {
                loadingController.dismiss().then(r => console.log(r));
                if (error.request.status == 0) {
                     await store.dispatch('userStore/logout');
                    if (error.config.url.includes('oauth/token')) {
                        await store.dispatch('userStore/logout');
                        throw error;
                    } else {
                        try {
                            await store.dispatch('user/refreshToken');
                            return this.customRequest({
                                method: error.config.method,
                                url: error.config.url,
                                data: error.config.data
                            });
                        } catch (e) {
                            throw error;
                        }
                    }
                }
                throw error;
            }
        );
    },

    unmount401Interceptor() {
        axios.interceptors.response.eject(this.code401interceptor);
    }
}

export default ApiService;