class ResponseError extends Error {
    public errorCode: any;
    public errorMessage: any;

    constructor(errorCode: any, message: string | undefined) {
        super(message);
        this.name = this.constructor.name;
        if (message != null) {
            this.errorMessage = message;
        }
        this.errorCode = errorCode;
    }
}

export { ResponseError };