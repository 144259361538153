import { ref, watch } from 'vue';
import { loadingController, alertController, toastController } from '@ionic/vue';

export function shared() {
    const formErrors = ref<any>([]);
    const formErrorObj: any = {};

    const showLoading = async(duration = 5000) => {
        const timeout = 5000;
        const loading = await loadingController.create({
            message: 'Please wait...',
            //duration: duration
        });
        await loading.present();

        setTimeout(() => {
            loading.dismiss();
        }, timeout);
    }

    const dismissLoading = async() => {
        loadingController.dismiss(null);
    }

    const getFormErrors = async(errors: any) => {
        //Reset form errors
        formErrors.value = {};
        if (Array.isArray(errors)) {
            // Add errors
            errors.forEach((error: any) => {
                formErrorObj[error.field] = error.message;
                formErrors.value = formErrorObj; 
            });
        } else {
            const toastMessage = {
                header: 'Error message',
                message: errors.message,
                position: 'middle',
                color: 'danger',
                duration: 1000
              };
              showToast(toastMessage)
        }
    }

    const clearFormErrors = async() => {
        formErrors.value = {};
    }

    const showAlert = async(dataObj: any) => {
        const { header, subHeader, messages } = dataObj;
        let messageData: any = [] || '';
        // if(messages.length > 0) {
        //     messages.forEach((msg: any) => {
        //         messageData = msg.message;
        //     });
        // } else {
            messageData = messages;
        //}

        const alertCtrl = await alertController
            .create({
              header: header,
              subHeader: subHeader,
              message: messageData,
              buttons: ['OK'],
            });
        await alertCtrl.present()
    }

    const showToast = async(dataObj: any) => {
        return toastController.create({
          header: dataObj.header,
          message: dataObj.message,
          position: dataObj.position || 'middle',
          color: dataObj.color || 'success',
          //duration: dataObj.duration || 3000,
          buttons: [
            {
              text: 'OK',
              role: 'cancel',
              handler: () => {
                /*if(dataObj.handler === 'deleteCart') {

                }*/
              }
            }
          ]
        })
        .then(toastEl => {
          toastEl.present();
        });
     }
     const getShortDate = (event: any) => {
        const date = new Date(event.detail.value);
        const year = date.getFullYear();

        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        const formatedDate =  month + '/' + day + '/' + year;
        return formatedDate;
     }

    watch(formErrors, function(oldValue, newValue) {
        newValue;
    });

    return {
        showLoading,
        dismissLoading,
        getFormErrors,
        clearFormErrors,
        formErrors,
        showAlert,
        showToast,
        getShortDate,
    }
}