import { ResponseError } from '@/common/ResponseError';
import { CountryService } from '@/common/country.service';

const state = {
    countries: []
};

const getters = {
    countries: (state: { countries: any }) => {
        return state.countries
    },
};

const actions = {
    async getCountries(context: any, searchKey: string) {
        context.commit('dataRequest');
        try {
            const response = await CountryService.getCountries(searchKey);
            context.commit('dataRequestSuccess', response.data.countryResultSet.countries);
            return response;
        } catch (error: any) {
            if (error instanceof ResponseError) {
                context.commit('setRequestError', {
                    errorMessage: error.errorMessage,
                    errorMessageCode: error.errorCode
                });
            }
            return error.message;
        }
    },
};

const mutations = {
    // Data request
    dataRequest(state: {
        countries: any;
        errorMessageCode: number;
        errorMessage: string;
    }) {
        state.countries = null;
        state.errorMessageCode = 0;
        state.errorMessage = "";
    },

    dataRequestSuccess(state: { countries: any }, payload: any) {
        state.countries = payload;
    },

    setRequestError(state: {
        errorMessageCode: any;
        errorMessage: any;
        }, {errorCode, errorMessage}: any) {
            state.errorMessageCode = errorCode;
            state.errorMessage = errorMessage;
       
    },
};

export const countryStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}