import { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import ApiService from '@/common/api.service';
import { TokenService } from '@/common/token.service';
import { AuthenticationError } from '@/common/AuthenticationError';
import { ResponseError } from '@/common/ResponseError';

const UserService = {
    login: async function(loginData: any) {
        const requestData: AxiosRequestConfig = {
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: 'Basic ' + btoa(process.env.VUE_APP_CLIENT_ID + ':' + process.env.VUE_APP_CLIENT_SECRET)
            },
            url: "/user/login",
            data: new URLSearchParams(loginData).toString()
        };

        try {
            const response = await ApiService.customRequest(requestData);
            TokenService.saveAuthData(response.data);
            ApiService.setHeader();
            ApiService.mount401Interceptor();

            return response.data;
        } catch (error) {
            this.catchError(error);
        }
    },

    refreshToken: async function() {
        const refreshToken = TokenService.getRefreshToken();

        const requestData: AxiosRequestConfig = {
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: 'Basic ' + btoa(process.env.VUE_APP_CLIENT_ID + ':' + process.env.VUE_APP_CLIENT_SECRET)
            },
            url: "/oauth/token",
            data: qs.stringify({
                "grant_type": "refresh_token",
                refreshToken: refreshToken
            })
        };

        try {
            const response = await ApiService.customRequest(requestData);

            TokenService.saveAuthData(response.data);
            ApiService.setHeader();

            return response.data;
        } catch (error:any) {
            throw new AuthenticationError(
                error.response.status,
                error.response.data
            );
        }
    },

    logout() {
        TokenService.removeAuthData();
        ApiService.removeHeader();
        ApiService.unmount401Interceptor();
    },

    register: async function(registerData: any) {
        const configOptions: AxiosRequestConfig = {
            method: "post",
            headers: { "Content-Type": "application/json" },
            url: "/user/register",
            data: registerData
        };

        try {
            return await ApiService.customRequest(configOptions);
        } catch (error: any) {
            this.catchError(error);
        }
    },

    forgotPassword: async function(formData: any) {
        const configOptions: AxiosRequestConfig = {
            method: "post",
            headers: { "Content-Type": "application/json" },
            url: "/user/forgotpassword",
            data: formData
        };

        try {
            return await ApiService.customRequest(configOptions);
        } catch (error: any) {
            this.catchError(error);
        }
    },

    resetPassword: async function(dataObj: any) {
        const { formInput, token } = dataObj;
        const configOptions: AxiosRequestConfig = {
            method: "post",
            headers: { "Content-Type": "application/json" },
            url: `/user/resetpassword?token=${token}`,
            data: formInput
        };

        try {
            return await ApiService.customRequest(configOptions);
        } catch (error: any) {
            this.catchError(error);
        }
    },

    getProfile: async function(userNumber: string) {
        try {
            return ApiService.get(`/user/view?userNumber${userNumber}`);
        } catch (error: any) {
            throw new ResponseError(
                error.status,
                error.error.message
            );
        }
    },
    
    getAccount: async function() {
        try {
            return ApiService.get('/user/profile');
        } catch (error: any) {
            throw new ResponseError(
                error.status,
                error.error.message
            );
        }
    },

    profileUpdate: async function(formData: any) {
        const configOptions: AxiosRequestConfig = {
            method: "post",
            headers: { "Content-Type": "application/json" },
            url: "/user/update",
            data: formData
        };

        try {
            return await ApiService.customRequest(configOptions);
        } catch (error: any) {
            this.catchError(error);
        }
    },

    changeAvatar: async function(formData: any) {
        const configOptions: AxiosRequestConfig = {
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
            url: "/user/uploadimage",
            data: formData
        };

        try {
            return await ApiService.customRequest(configOptions);
        } catch (error: any) {
            this.catchError(error);
        }
    },

    changeEmail: async function(formData: any) {
        const configOptions: AxiosRequestConfig = {
            method: "post",
            headers: { "Content-Type": "application/json" },
            url: "/user/changeemail",
            data: formData
        };

        try {
            return await ApiService.customRequest(configOptions);
        } catch (error: any) {
            console.log('error', error);
            this.catchError(error);
        }
    },

    changePassword: async function(formData: any) {
        const configOptions: AxiosRequestConfig = {
            method: "post",
            headers: { "Content-Type": "application/json" },
            url: "/user/changepassword",
            data: formData
        };

        try {
            return await ApiService.customRequest(configOptions);
        } catch (error: any) {
            this.catchError(error);
        }
    },

    catchError: function(error: any) {
        let status;
        let description;

        if (error.response === undefined) {
            status = error.message;
            description = error.message;
        } else {
            status = error.response.status;
            description = error.response.data;
        }

        throw new AuthenticationError(status, description);
    }
}

export { UserService };