import { ResponseError } from '@/common/ResponseError';
import { HomeService } from '@/views/home.service';

const state = {
    home: [],
    responseErroCode: 0,
    responseError: '',
};

const getters = {
    responseErrorCode: (state: {responseErrorCode: any }) => {
        return state.responseErrorCode;
    },
    responseError: (state: {responseError: any }) => {
        return state.responseError;
    },
    home: (state: {home: any }) => {
        return state.home;
    },
};

const actions = {
    async getHome(context: any) {
        context.commit('dataRequest');
        try {
            const response = await HomeService.getHome();
            context.commit('dataRequestSuccess', response.data);
            return response;
        } catch(errors: any) {
            if (errors instanceof ResponseError) {
                context.commit('setRequestError', {
                    responseErrorCode: errors.errorCode,
                    errorMessage: errors.errorMessage
                })
            }
            return errors.message;
        }
    }
};

const mutations = {
    // Data request
    dataRequest(state: {
        home: any;
        errorMessageCode: number;
        errorMessage: string;
    }) {
        state.home = null;
        state.errorMessageCode = 0;
        state.errorMessage = '';
    },

    dataRequestSuccess(state: { home: string }, payload: any) {
        state.home = payload;
    },

    setRequestError(state: {
        errorMessageCode: any;
        errorMessage: any;
        }, {errorCode, errorMessage}: any) {
            state.errorMessageCode = errorCode;
            state.errorMessage = errorMessage;
       
    },
};

export const homeStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}