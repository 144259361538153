import { createStore } from 'vuex';
import { userStore } from '@/store/user.store';
import { homeStore } from '@/store/home.store';
import { listingStore } from '@/store/listing.store';
import { countryStore } from '@/store/country.store';

export const store = createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        userStore,
        homeStore,
        listingStore,
        countryStore
    },
})