import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/HomePage.vue')
  },{
    path: '/plan',
    name: 'PlanPage',
    component: () => import('@/views/PlanPage.vue')
  },{
    path: '/listing',
    name: 'ListingPage',
    component: () => import('@/views/listing/ListingPage.vue')
  },{
    path: '/listing/:listId',
    name: 'ViewPage',
    component:() => import('@/views/listing/ViewPage.vue')
  },{
    path: '/listing/post',
    name: 'PostPage',
    component: () => import('@/views/listing/PostPage.vue')
  },{
    path: '/menu',
    name: 'MenuPage',
    component: () => import('@/views/MenuPage.vue')
  },{
    path: '/about',
    name: 'AboutPage',
    component: () => import('@/views/AboutPage.vue')
  },{
    path: '/contact',
    name: 'ContactPage',
    component: () => import('@/views/ContactPage.vue')
  },{
    path: '/toc',
    name: 'TermConditionPage',
    component: () => import('@/views/TermConditionPage.vue')
  },{
    path: '/privacy',
    name: 'PrivacyPolicyPage',
    component: () => import('@/views/PrivacyPolicyPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
