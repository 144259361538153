import ApiService from '@/common/api.service';
import { ResponseError } from '@/common/ResponseError';

const CountryService = {

    getCountries: async function(searchKey: string) {
        try {
            return ApiService.get(`/country/list?searchKey=${searchKey}`);
        } catch (error: any) {
            throw new ResponseError(
                error.status,
                error.error.message
            );
        }
    },
}

export { CountryService };