import ApiService from "@/common/api.service";
import { ResponseError } from "@/common/ResponseError";

const HomeService = {
    getHome: async() => {
        try {
            return ApiService.get('/listing/home');
        } catch(errors: any) {
            throw new ResponseError(
                errors.status,
                errors.error.message
            )
        }
    },
}

export { HomeService };