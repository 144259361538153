export const ConfigUtil = {
    appName: 'Jirani City ',
    appLogo: 'City<ion-icon :icon="navigateCircleOutline"></ion-icon>Hop',
    authDataKey: 'jcAuthData',
    loginRoute: '/user/login',
    businessRoute: '/tabs/business',
    forgotRoute: '/user/forgotpassword',
    prefixOptions: ['N/A', 'Mr', 'Mrs', 'Ms', 'Miss'],
    genderOptions: ['N/A', 'Male', 'Female'],
    maritalOptions: ['N/A', 'Married', 'Widowed', 'Separated', 'Divorced', 'Single'],
    accessLevelOptions: ['Private', 'Public'],
    statusOptions: ['Publish', 'Draft'],
    dateOptions: [
        '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15',
        '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
    ],
    monthOptions: [
        '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'
    ],
    categoryOptions: [
        
        {thumbnail: '/assets/images/gadgets.png', name: 'All gadgets'},
        {thumbnail: '/assets/images/automobile.png', name: 'Automobiles'},
        {thumbnail: '/assets/images/business.png', name: 'Business'},
        {thumbnail: '/assets/images/jobs.png', name: 'Jobs'},
        {thumbnail: '/assets/images/education.png', name: 'Professionals'},
        {thumbnail: '/assets/images/properties.png', name: 'Property'},
        {thumbnail: '/assets/images/service.jpg', name: 'Services'},
        {thumbnail: '/assets/images/machinery.jpg', name: 'Machinery'},
        {thumbnail: '/assets/images/furniture.jpg', name: 'Furniture'},
        {thumbnail: '/assets/images/farming.jpg', name: 'Farming'},
        {thumbnail: '/assets/images/animals.png', name: 'Animals'},
        {thumbnail: '/assets/images/fashion.jpg', name: 'Fashion'},
        {thumbnail: '/assets/images/electronic.jpg', name: 'Electronics'},
        {thumbnail: '/assets/images/hospitality.jpg', name: 'Hospitality'},
        {thumbnail: '/assets/images/fooddining.jpg', name: 'Food and Dining'},
        {thumbnail: '/assets/images/education.jpg', name: 'Education'},
        {thumbnail: '/assets/images/software.jpg', name: 'Software'},
        {thumbnail: '/assets/images/travel.jpg', name: 'Travel'},
        {thumbnail: '/assets/images/entertainment.jpg', name: 'Entertainment'},
        {thumbnail: '/assets/images/sport.jpg', name: 'Sports'},
        {thumbnail: '/assets/images/giftflower.jpg', name: 'Gifts and Flowers'},
        {thumbnail: '/assets/images/free.png', name: 'Free'}

    ],
    paymentOptions: [
        'Fixed',
        'Negotiable',
        'One time',
        'Daily',
        'Weekly',
        'Monthly',
        'Yearly'
    ],
    conditionOptions: [
        'New',
        'Used',
        'Other'
    ],
    listingMenuOptions: [
        'All',
        ' | Latest',
        ' | Featured',
        ' | Category',
        ' | Country'
    ],
    typeOptions: [
        'For sale',
        'For free',
        'Old',
        'Young',
        'Small',
        'Medium',
        'Large',
        'Advertisement',
        'Other'
    ]
};
