import { AuthenticationError } from '@/common/AuthenticationError';
import { ResponseError } from '@/common/ResponseError';
import { UserService } from '@/views/user/user.service';
import { TokenService } from '@/common/token.service';

const state = {
    isAuthenticated: TokenService.getAuthStatus(),
    authData: TokenService.getAuthData(),
    profileData: null,
    errorMessageCode: 0,
    errorMessage: '',
    refreshTokenPromise: null,
    
};

const getters = {
    isAuthenticated: (state: { isAuthenticated: any }) => {
        return state.isAuthenticated;
    },

    authData: (state: { authData: any }) => {
        return state.authData;
    },
    
    profileData: (state: { profileData: any }) => {
        return state.profileData
    },

    errorMessageCode: (state: { errorMessageCode: any }) => {
        return state.errorMessageCode;
    },

    errorMessage: (state: { errorMessage: any }) => {
        return state.errorMessage;
    },
};

const actions = {
    async login(context: any, formData: any) {
        context.commit('loginRequest');
        return new Promise((resolve, reject) => {
            UserService.login(formData).then(response => {
                context.commit('setLogin', response);
                resolve(response);
            }).catch(err => {
                if (err instanceof AuthenticationError) {
                    context.commit('setLoginRegisterForgotResetError', {
                        errorMessageCode: err.errorCode,
                        errorMessage: err.message
                    });
                    reject(err.message);
                }
            });
        });
    },

    logout(context: any) {
        context.commit('setLogoutRequest');
        return new Promise((resolve:any) => {
            UserService.logout();
            resolve();
        });
    },

    refreshToken(context: any, state: { refreshTokenPromise: any }) {
        if (!state.refreshTokenPromise) {
            const tokenRequest = UserService.refreshToken();
            context.commit('setRefreshTokenPromise', tokenRequest);
            tokenRequest.then(
                response => {
                    context.commit('setRefreshTokenPromise', null);
                    context.commit('setLogin', response);
                },
                error => {
                    context.commit('setRefreshTokenPromise', error);
                }
            );
        }

        return state.refreshTokenPromise;
    },

    async register(context: any, formData: any) {
        return new Promise((resolve, reject) => {
            UserService.register(formData).then(response => {
                context.commit('setRegisterForgotResetAuthentication', response);
                resolve(response);
            }).catch(err => {
                if (err instanceof AuthenticationError) {
                    context.commit('setLoginRegisterForgotResetError', {
                        errorMessageCode: err.errorCode,
                        errorMessage: err.message
                    });
                    reject(err.message);
                }
            });
        });
    },

    async forgotPassword(context: any, formData: any) {
        return new Promise((resolve, reject) => {
            UserService.forgotPassword(formData).then(response => {
                context.commit('setRegisterForgotResetAuthentication', response);
                resolve(response);
            }).catch(err => {
                if (err instanceof AuthenticationError) {
                    context.commit('setLoginRegisterForgotResetError', {
                        errorMessageCode: err.errorCode,
                        errorMessage: err.message
                    });
                    reject(err.message);
                }
            });
        });
    },

    async resetPassword(context: any, dataObj: any) {
        return new Promise((resolve, reject) => {
            UserService.resetPassword(dataObj).then(response => {
                context.commit('setRegisterForgotResetAuthentication', response);
                resolve(response);
            }).catch(err => {
                if (err instanceof AuthenticationError) {
                    context.commit('setLoginRegisterForgotResetError', {
                        errorMessageCode: err.errorCode,
                        errorMessage: err.message
                    });
                    reject(err.message);
                }
            });
        });
    },

    async setAuthenticatingStatus(context: any, status: any) {
        context.commit('setAuthenticatingStatus', status);
    },

    async getAccount(context: any) {
        context.commit('dataRequest');
        try {
            const response = await UserService.getAccount();
            context.commit('dataRequestSuccess', response);
            return response;
        } catch (error: any) {
            if (error instanceof ResponseError) {
                context.commit('setRequestError', {
                    errorMessage: error.errorMessage,
                    errorMessageCode: error.errorCode
                });
            }
            return error.message;
        }
    },

    async profileUpdate(context: any, formData: any) {
        return new Promise((resolve, reject) => {
            UserService.profileUpdate(formData).then((response: any) => {
                resolve(response);
            }).catch((error: any) => {
                if (error instanceof ResponseError) {
                    context.commit('setRequestError', {
                        errorMessageCode: error.errorCode,
                        errorMessage: error.message
                    });
                    reject(error.message);
                }
            });
        });
    },

    async changeEmail(context: any, formData: any) {
        return new Promise((resolve, reject) => {
            UserService.changeEmail(formData).then((response: any) => {
                resolve(response);
            }).catch((error: any) => {
                if (error instanceof ResponseError) {
                    context.commit('setRequestError', {
                        errorMessageCode: error.errorCode,
                        errorMessage: error.message
                    });
                    reject(error.message);
                }
            });
        });
    },

    async changePassword(context: any, formData: any) {
        return new Promise((resolve, reject) => {
            UserService.changePassword(formData).then(response => {
                resolve(response);
            }).catch((error: any) => {
                if (error instanceof ResponseError) {
                    context.commit('setRequestError', {
                        errorMessageCode: error.errorCode,
                        errorMessage: error.message
                    });
                    reject(error.message);
                }
            });
        });
    },

    async changeAvatar(context: any, formData: any) {
        return new Promise((resolve, reject) => {
            UserService.changeAvatar(formData).then(response => {
                resolve(response);
            }).catch((error: any) => {
                if (error instanceof ResponseError) {
                    context.commit('setRequestError', {
                        errorMessageCode: error.errorCode,
                        errorMessage: error.message
                    });
                    reject(error.message);
                }
            });
        });
    },

};

const mutations = {
    loginRequest(state: {
        authData: any;
        isAuthenticated: boolean;
        errorMessage: string;
        errorMessageCode: number;
    }) {
        state.authData = null;
        state.isAuthenticated = true;
        state.errorMessage = '';
        state.errorMessageCode = 0;
    },

    setLogin(state: {
        authData: any;
        isAuthenticated: boolean;
    }, authData: any) {
        state.authData = authData;
        state.isAuthenticated = true;
    },

    setRegisterForgotResetAuthentication(state: { isAuthenticated: boolean }) {
        state.isAuthenticated = false;
    },

    setLoginRegisterForgotResetError(state: {
        authData: any;
        isAuthenticated: boolean;
        errorMessageCode: any;
        errorMessage: any;
    }, {errorCode, errorMessage}: any) {
        state.authData = null;
        state.isAuthenticated = false;
        state.errorMessageCode = errorCode;
        state.errorMessage = errorMessage;
    },

    setLogoutRequest(state: { authData: any; isAuthenticated: boolean }) {
        state.authData = null;
        state.isAuthenticated = false;
    },

    setRefreshTokenPromise(state: { refreshTokenPromise: any }, promise: any) {
        state.refreshTokenPromise = promise;
    }, 
    
    // Data request
    dataRequest(state: {
        profileData: any;
        errorMessageCode: number;
        errorMessage: string;
    }) {
        state.profileData = null;
        state.errorMessageCode = 0;
        state.errorMessage = '';
    },

    dataRequestSuccess(state: { profileData: any }, payload: any) {
        state.profileData = payload;
    },

    setRequestError(state: {
        errorMessageCode: any;
        errorMessage: any;
        }, {errorCode, errorMessage}: any) {
            state.errorMessageCode = errorCode;
            state.errorMessage = errorMessage;
       
    },
};

export const userStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}