
  import { 
    IonApp, 
    IonRouterOutlet ,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonMenu,
    IonContent,
    IonList,
    IonItem,
   } from '@ionic/vue';
  import { computed, defineComponent } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { shared } from '@/common/shared';
  export default defineComponent({
    name: 'App',
    components: {
      IonApp,
      IonRouterOutlet,
      IonHeader,
      IonToolbar,
      IonTitle,
      IonMenu,
      IonContent,
      IonList,
      IonItem,
    },
    setup() {
      const store = useStore();
            const router = useRouter();
      const { showLoading, dismissLoading } = shared();
      const isAuthenticated = computed(() => {
          return store.state.userStore.isAuthenticated;
      })

      const username = computed(() => {
          return store.state.userStore.authData.username || '';
      })

      const logout = async() => {
        showLoading();
        await store.dispatch('userStore/logout').then(() => {
            dismissLoading();
            router.push('/');
            // Hack to clear some cache
            location.reload();
          }).catch((errors: any) => {
              console.log(errors);
        });

      }

      return {
        isAuthenticated,
        username,
        logout,
      }
    }
});
