import { ResponseError } from '@/common/ResponseError';
import { ListingService } from '@/views/listing/listing.service';

const state = {
    listings: [],
    listing: {},
    responseErroCode: 0,
    responseError: '',
};

const getters = {
    responseErrorCode: (state: {responseErrorCode: any }) => {
        return state.responseErrorCode;
    },

    responseError: (state: {responseError: any }) => {
        return state.responseError;
    },

    listings: (state: { listings: any }) => {
        return state.listings;
    },

    listing: (state: { listing: any }) => {
        return state.listing;
    }
};

const actions = {
    async getManage(context: any, queryParams: any) {
        context.commit('listingsRequest');
        try {
            const response = await ListingService.getManage(queryParams);
            context.commit('listingsSuccess', response.data.listingResultSet.listings);
            return response;
        } catch(errors: any) {
            if (errors instanceof ResponseError) {
                context.commit('setRequestError', {
                    responseErrorCode: errors.errorCode,
                    errorMessage: errors.errorMessage
                });
            }
            return errors.message;
        }
    },

    async getListings(context: any, queryParams: any) {
        context.commit('listingsRequest');
        try {
            const response = await ListingService.getListings(queryParams);
            context.commit('listingsSuccess', response.data.listResultSet.list);
            return response;
        } catch(errors: any) {
            if (errors instanceof ResponseError) {
                context.commit('setRequestError', {
                    responseErrorCode: errors.errorCode,
                    errorMessage: errors.errorMessage
                });
            }
            return errors.message;
        }
    },

    async getListing(context: any, listId: number) {
        context.commit('listingRequest');
        try {
            const response = await ListingService.getListing(listId);
            context.commit('listingSuccess', response.data.listingResult)
            return response;
        } catch(errors: any) {
            if (errors instanceof ResponseError) {
                context.commit('setRequestError', {
                    responseErrorCode: errors.errorCode,
                    errorMessage: errors.errorMessage
                })
            }
            return errors.message;
        } 
    },

    async post(context: any, formData: any) {
        return new Promise((resolve, reject) => {
            ListingService.post(formData).then((response: any) => {
                resolve(response);
            }).catch((error: any) => {
                //if (error instanceof ResponseError) {
                context.commit('setRequestError', {
                    errorMessageCode: error.errorCode,
                    errorMessage: error.message
                });
                reject(error.message);
               // }
            });
        });
    },

    async update(context: any, formData: any) {
        return new Promise((resolve, reject) => {
            ListingService.update(formData).then((response: any) => {
                resolve(response);
            }).catch((error: any) => {
                //if (error instanceof ResponseError) {
                context.commit('setRequestError', {
                    errorMessageCode: error.errorCode,
                    errorMessage: error.message
                });
                reject(error.message);
                //}
            });
        });
    },

    
    async getManageView(context: any, listId: number) {
        context.commit('listingRequest');
        try {
            const response = await ListingService.getManageView(listId);
            context.commit('listingSuccess', response.data.listingResult)
            return response;
        } catch(errors: any) {
            //if (errors instanceof ResponseError) {
            context.commit('setRequestError', {
                responseErrorCode: errors.errorCode,
                errorMessage: errors.errorMessage
            })
            //}
            return errors.message;
        } 
    },

    async uploadImage(context: any, formData: any) {
        return new Promise((resolve, reject) => {
            ListingService.uploadImage(formData).then(response => {
                resolve(response);
            }).catch((error: any) => {
                context.commit('setRequestError', {
                    errorMessageCode: error.errorCode,
                    errorMessage: error.message
                });
                reject(error.message);
            });
        });
    },

    async updateConnection(context: any, formData: any) {
        return new Promise((resolve, reject) => {
            ListingService.updateConnection(formData).then((response: any) => {
                resolve(response);
            }).catch((error: any) => {
                context.commit('setRequestError', {
                    errorMessageCode: error.errorCode,
                    errorMessage: error.message
                });
                reject(error.message);
            });
        });
    },

    async updateFundingDetails(context: any, formData: any) {
        return new Promise((resolve, reject) => {
            ListingService.updateFundingDetails(formData).then((response: any) => {
                resolve(response);
            }).catch((error: any) => {
                    context.commit('setRequestError', {
                        errorMessageCode: error.errorCode,
                        errorMessage: error.message
                    });
                    reject(error.message);
            });
        });
    },

    async updateEnhance(context: any, formData: any) {
        return new Promise((resolve, reject) => {
            ListingService.updateEnhance(formData).then((response: any) => {
                resolve(response);
            }).catch((error: any) => {
                    context.commit('setRequestError', {
                        errorMessageCode: error.errorCode,
                        errorMessage: error.message
                    });
                    reject(error.message);
            });
        });
    },

    async updatePayout(context: any, formData: any) {
        return new Promise((resolve, reject) => {
            ListingService.updatePayout(formData).then((response: any) => {
                resolve(response);
            }).catch((error: any) => {
                context.commit('setRequestError', {
                    errorMessageCode: error.errorCode,
                    errorMessage: error.message
                });
                reject(error.message);
            });
        });
    },
};

const mutations = {
    // Businesses request
    listingsRequest(state: {
        listings: any;
        errorMessageCode: number;
        errorMessage: string;
    }) {
        state.listings = null;
        state.errorMessageCode = 0;
        state.errorMessage = '';
    },

    listingsSuccess(state: { listings: any }, payload: any) {
        state.listings = payload;
    },

    listingRequest(state: {
        listing: any;
        errorMessageCode: number;
        errorMessage: string;
    }) {
        state.listing = null;
        state.errorMessageCode = 0;
        state.errorMessage = '';
    },

    listingSuccess(state: { listing: any }, payload: any) {
        state.listing = payload;
    },

    setRequestError(state: {
        errorMessageCode: any;
        errorMessage: any;
        }, {errorCode, errorMessage}: any) {
            state.errorMessageCode = errorCode;
            state.errorMessage = errorMessage;
       
    },
};

export const listingStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}