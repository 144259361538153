import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_menu, {
        "content-id": "main",
        slot: "primary",
        class: "mobile-header"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Menu ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_list, { class: "cursor-pointer" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, { routerLink: "/" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Home ")
                    ]),
                    _: 1
                  }),
                  (_ctx.isAuthenticated)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 0,
                        routerLink: "/dashboard"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Dashboard ")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isAuthenticated)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 1,
                        routerLink: "/user/myaccount"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" My Account ")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isAuthenticated)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 2,
                        routerLink: "/user/profile"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" My profile ")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_item, {
                    tab: "business",
                    href: "/tabs/business"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Businesses ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, {
                    tab: "realestate",
                    href: "/tabs/realestate"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Real Estate ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { href: "/tabs/manage/create" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Create a campaign ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { href: "/tabs/more" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" More ")
                    ]),
                    _: 1
                  }),
                  (!_ctx.isAuthenticated)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 3,
                        href: "/user/login"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Login ")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (!_ctx.isAuthenticated)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 4,
                        href: "/signup"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Sign up ")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isAuthenticated)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 5,
                        class: "cursor-pointer",
                        onClick: _ctx.logout
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Logout ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_router_outlet, { id: "main" })
    ]),
    _: 1
  }))
}